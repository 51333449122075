import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Metrics.module.scss";

import PatientMetrics from "../../../components/userMetric/PatientMetrics";
import PatientGraphView from "../userInformation/graphView/NextStepGraphView";
import {
  calculateActiveDaysAndTaskCompliance,
  convertDailyMissionsToMetricData,
  convertMissionScoresToMetricData,
  getMetricsMissionIds
} from "../userInformation/NextStepUserInformation.helpers";

import PulseLoader from "~/components/pulseLoader/PulseLoader";
import Tabs from "~/components/tabs/Tabs";
import { useMessageThreads } from "~/hooks/useApi/useMessageThreads";
import useMissions from "~/hooks/useApi/useMissions";
import useMissionSummary from "~/hooks/useApi/useMissionSummary";
import { usePatientMetrics } from "~/hooks/useApi/usePatientMetrics";
import { UserDetails } from "~/typing/sidekickTypes";

type MetricsProps = {
  programId: string;
  locale: string;
  userDetail: UserDetails;
};
const Metrics = ({ programId, locale, userDetail }: MetricsProps) => {
  const { t } = useTranslation();

  const [loading, setIsLoading] = useState(false);
  const [activeView, setActiveView] = useState("metrics");

  const { messageThreads } = useMessageThreads({
    userId: userDetail.id,
    programId,
    locale
  });

  const { missions } = useMissions();

  const { slotDays, isLoading: missionSummaryLoading } = useMissionSummary({
    userId: userDetail.id,
    programId,
    locale,
    startDate: DateTime.now().minus({ weeks: 2 }).toISODate() ?? "",
    endDate: DateTime.now().toISODate() ?? ""
  });

  const {
    energyMissionId,
    sleepMissionId,
    stressMissionId
  } = getMetricsMissionIds(missions ?? []);

  const {
    sleepScores,
    energyScores,
    stressScores,
    isLoading: metricsLoading
  } = usePatientMetrics({
    userId: userDetail.id,
    programCatalogItemId: programId,
    locale,
    sleepMissionId,
    energyMissionId,
    stressMissionId
  });

  const metricDataOverLastWeek = useMemo(() => {
    setIsLoading(true);
    const activeDaysMetricData = calculateActiveDaysAndTaskCompliance(
      slotDays,
      messageThreads,
      userDetail
    );
    const dailySummaryMetricData = convertDailyMissionsToMetricData({
      dailySummary: userDetail?.dailySummary
    });

    const missionScoresMetricData = convertMissionScoresToMetricData({
      sleepScores,
      energyScores,
      stressScores
    });
    setIsLoading(false);
    return [
      ...activeDaysMetricData,
      ...dailySummaryMetricData,
      ...missionScoresMetricData
    ];
  }, [
    slotDays.length,
    sleepScores.length,
    energyScores.length,
    stressScores.length,
    userDetail
  ]);

  if (loading || metricsLoading || missionSummaryLoading)
    return (
      <section className={styles.loadingWrapper}>
        <PulseLoader inverted />
      </section>
    );

  return (
    <div className={styles.metricsWrapper}>
      <Tabs
        tabs={[
          {
            title: t("nextStep.userMetrics"),
            value: "metrics",
            onClick: (value) => {
              setActiveView(value);
              // trackNextStepUserMetricsViewed();
            }
          },
          {
            title: t("nextStep.graphView"),
            value: "graph",
            onClick: setActiveView
          }
        ]}
        activeTab={activeView}
      />
      <section>
        {activeView === "graph" ? (
          <PatientGraphView />
        ) : (
          <PatientMetrics metrics={metricDataOverLastWeek} />
        )}
      </section>
    </div>
  );
};

export default Metrics;
