import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useNextUserSearchParamsFilters = () => {
  const [programFilters, setProgramFilters] = useState<
    {
      programCatalogItemId: string;
      programCatalogItemName: string;
    }[]
  >([]);
  const [taskFilters, setTaskFilters] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const programFilters = JSON.parse(
      decodeURIComponent(searchParams.get("programFilters") || "[]")
    );
    const taskIds = searchParams.get("taskFilters")?.split(",") || [];

    setProgramFilters(programFilters);
    setTaskFilters(taskIds);
  }, [searchParams]);

  const removeProgramFilter = (programCatalogItemId: string) => {
    const newProgramFilters = programFilters.filter(
      (programFilter) =>
        programFilter.programCatalogItemId !== programCatalogItemId
    );

    if (newProgramFilters.length === 0) {
      searchParams.delete("programFilters");
    } else {
      const encodedProgramFilters = encodeURIComponent(
        JSON.stringify(newProgramFilters)
      );

      searchParams.set("programFilters", encodedProgramFilters);
    }

    setSearchParams(searchParams);
    setProgramFilters(newProgramFilters);
  };

  const removeTaskFilter = (task: string) => {
    const newTaskFilters = taskFilters.filter(
      (taskFilter) => taskFilter !== task
    );

    if (newTaskFilters.length === 0) {
      searchParams.delete("taskFilters");
    } else {
      searchParams.set("taskFilters", newTaskFilters.join(","));
    }

    setSearchParams(searchParams);
    setTaskFilters(newTaskFilters);
  };

  return { programFilters, taskFilters, removeProgramFilter, removeTaskFilter };
};
