import SkeletonCareManagerTask from "./SkeletonCareManagerTask";

type SkeletonCareManagerTasksProps = {
  quantity?: number;
};

export default function SkeletonCareManagerTasks({
  quantity = 5
}: SkeletonCareManagerTasksProps) {
  return (
    <>
      {Array.from({ length: quantity }).map((_, index) => (
        <SkeletonCareManagerTask key={`skeleton-care-task${index}`} />
      ))}
    </>
  );
}
