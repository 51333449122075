import useGraphQLQuery from "./useGraphQLQuery";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";

import { getCareManagerActionLogs } from "~/api/graphql/nextStep";
import { Maybe } from "~/gql/graphql";
import { CareManagerActionLogsDto } from "~/typing/graphql/types";

export const useCareManagerActionLogs = (props: {
  coachId: string;
  pageSize: number;
  pageNumber: number;
}) => {
  const {
    data,
    isLoading,
    isError,
    status,
    isFetching,
    invalidate
  } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.careManagerActionLogs(props.coachId),
    queryFn: () =>
      getCareManagerActionLogs({ ...props, coachId: parseInt(props.coachId) }),
    disabled: !props.coachId
  });

  return {
    careManagerActionLogs: data as Maybe<CareManagerActionLogsDto>,
    isLoading,
    isError,
    invalidate,
    status,
    isFetching
  };
};
