import useGraphQLQuery from "./useGraphQLQuery";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";

import { getFallbackMessage } from "~/api/graphql/nextStep";
import { QueryGetFallbackMessageArgs } from "~/typing/graphql/types";

export const useFallbackMessage = (
  props: QueryGetFallbackMessageArgs,
  enabled: boolean
) => {
  const { data, isLoading, isError, invalidate, status } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.fallbackMessage(props.locale, props.userId),
    queryFn: () => getFallbackMessage(props),
    staleTime: 1000 * 60 * 10, // 10 minutes This can be adjusted based on the requirement and testing,
    disabled: !enabled
  });

  return {
    fallbackMessage: data,
    isLoading,
    isError,
    invalidate,
    status
  };
};
