import styles from "./SkeletonCareManagerTasks.module.scss";
import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonCareManagerTask = () => {
  return (
    <div className={`skeleton-wrapper ${styles.wrapper}`}>
      <SkeletonElement type="avatar" />
      <div>
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
      </div>
      <SkeletonShimmer />
    </div>
  );
};

export default SkeletonCareManagerTask;
