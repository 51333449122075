import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./GroupsUpload.module.scss";

import UploadDocument from "../user/documents/UploadDocument";
import { UploadDocumentProps } from "../user/documents/UploadDocument";

import api from "~/api/api";
import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { FileUploadType } from "~/constants/fileUploadTypes";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

interface ExternalUserGroupError {
  message: string;
  data?: { [key: string]: string | number | boolean | null };
}

const GroupsUpload = () => {
  const [errorMessage, setErrorMessage] = useState<ExternalUserGroupError>();
  const navigate = useNavigate();
  const uploadGroups = async ({ name, encodedFile }): Promise<number> => {
    try {
      const res = await api.post<{ id: number }>(
        "/coach/externalusergroups/upload",
        {
          file: encodedFile,
          fileUploadType: FileUploadType.ElevanceGroupImport,
          uploadName: name
        }
      );

      if (res.data && res.data.id) {
        return res.data.id;
      } else {
        throw new Error(t("groups.error.unknown"));
      }
    } catch (error) {
      const messageObject = parseExternalUserGroupErrorResponse(error);
      if (messageObject) {
        throw messageObject;
      }
      throw { message: t("groups.error.unknown") };
    }
  };

  function parseExternalUserGroupErrorResponse(
    error
  ): ExternalUserGroupError | null {
    if (error && error.message) {
      const message = error.message;
      if (typeof message === "string" && message.startsWith("{")) {
        const messageObject = JSON.parse(message);
        if (isExternalUserGroupError(messageObject)) {
          return messageObject;
        }
      }
    }
    return null;
  }

  function isExternalUserGroupError(e): e is ExternalUserGroupError {
    return (
      "message" in e &&
      (!("data" in e) ||
        (typeof e.data === "object" &&
          Object.values(e.data).every((v) => typeof v !== "object")))
    );
  }

  const mutationUpload = useMutation({
    mutationFn: uploadGroups,
    onSuccess: async (fileUploadId) => {
      displaySuccessToast({
        message: t("groups.success.uploaded", { id: fileUploadId }),
        shouldDisappear: true
      });

      navigate(`/groups/${fileUploadId}`);
    },
    onError: (e) => {
      displayErrorToast({
        message: t("groups.error.upload_failed"),
        shouldDisappear: true
      });
      Sentry.captureException(e);

      if (isExternalUserGroupError(e)) {
        setErrorMessage(e);
      } else {
        setErrorMessage({ message: t("groups.error.unknown") });
      }
    }
  });

  const handleDocumentUpload: UploadDocumentProps["onUpload"] = async ({
    selectedFile,
    encodedFile
  }) => {
    const name = selectedFile?.name || t("groups.upload.unnamed");
    await mutationUpload.mutateAsync({ name, encodedFile });
  };

  return (
    <SentryErrorBoundary transactionName="GroupsUpload">
      <Layout title={t("groups.upload.title")}>
        <div data-testid="groups-page-wrapper" className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}></div>
            <p className={styles.description}>
              {t("groups.upload.description")}
            </p>
            <UploadDocument
              buttonText={t("general.confirm", "Confirm")}
              onUpload={handleDocumentUpload}
              acceptedFiles={"text/csv"}
            />

            {errorMessage && (
              <div className={styles.errorBox}>
                <p className={styles.error}>
                  {t("groups.error.label")}: {errorMessage.message}
                </p>
                {errorMessage.data && (
                  <p className={styles.error}>{t("groups.error.data")}</p>
                )}
                {errorMessage.data &&
                  Object.entries(errorMessage.data).map(([key, value]) => (
                    <p key={key} className={styles.error}>
                      {key}: {value}
                    </p>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default GroupsUpload;
