import _ from "lodash";
import { useEffect, useMemo, useReducer, useRef } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import api from "~/api/api";
import { addPinnedNote, removePinnedNote } from "~/api/requests/noteRequests";
import notesReducer, { NotesActions } from "~/components/notes/notesReducer";
import { journalItemTypes, MAX_PINNED_NOTES } from "~/constants/notes";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useUserContextInfo } from "~/hooks/graphql/useCareManagerTasks";
import useNotes from "~/hooks/useApi/useNotes";
import usePinnedNotes from "~/hooks/useApi/usePinnedNotes";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { NoteInfo } from "~/typing/sidekickTypes";

export const useUserNotes = () => {
  const { program_id, user_id, locale } = useParams();
  const { userContextInfo, status: userContextStatus } = useUserContextInfo({
    userId: user_id || "",
    enabled: !program_id && !locale
  });
  const programIdFallback =
    userContextStatus === "success"
      ? userContextInfo?.programCatalogItemId
      : "";
  const localeFallback =
    userContextStatus === "success" ? userContextInfo?.locale : "";

  const programId = program_id || programIdFallback || "";
  const localeCode = locale || localeFallback || "";
  const [sortedNotes, notesDispatch] = useReducer(notesReducer, []);
  const { trackNoteSaved } = useAmplitudeTracking();

  const {
    pinnedNotes = [],
    invalidate: invalidatePinnedNotes
  } = usePinnedNotes(programId, user_id);

  const {
    notes = [],
    isLoading: notesLoading,
    invalidate: invalidateNotes
  } = useNotes(programId, localeCode ?? "", user_id || "");

  const previousNotesRef = useRef(notes);

  const memoizedNotes = useMemo(() => notes ?? [], [notes]);

  const handleNotePinClick = async (note: NoteInfo) => {
    const pinnedNote = pinnedNotes?.find(
      (pinned) => pinned.journalItemId === note.id
    );

    if (pinnedNote) {
      await removePinnedNote(user_id || "", pinnedNote.id);
    } else {
      if ((pinnedNotes?.length ?? 0) >= MAX_PINNED_NOTES) {
        displayErrorToast({ message: t("notes.tooManyPins") });
      } else {
        await addPinnedNote(programId, user_id || "", note.id);
      }
    }
    invalidatePinnedNotes();
  };

  const addNote = async (
    newNote: string,
    itemType: number,
    isPinned: boolean
  ) => {
    toast.remove();
    const path = `/coach/programs/${programId}/locales/${localeCode}/users/${user_id}/journalitems`;
    const payload = {
      text: newNote,
      pinned: isPinned,
      itemType
    };
    return await api
      .post(path, payload)
      .then(() => {
        trackNoteSaved({
          category:
            journalItemTypes.find((type) => type.itemType === itemType)
              ?.title ?? "",
          message: newNote
        });
        updateNotes();
        displaySuccessToast({
          message: t("notes.success", {
            itemType: t("notes.itemType.note")
          })
        });
        return true;
      })
      .catch(() => {
        displayErrorToast({
          message: t("notes.error", {
            itemType: t("notes.itemType.note")
          })
        });
        return false;
      });
  };

  const archiveNote = async (note, pinned) => {
    const path = `/coach/programs/${programId}/locales/${localeCode}/users/${user_id}/journalitems/${note.id}`;
    const response = await api.put(path, {
      archived: !note.archived,
      pinned: false
    });

    if (response?.status === 200) {
      if (pinned && !note.archived) {
        const pinnedNote = pinnedNotes.find(
          (pinned) => pinned.journalItemId === note.id
        );

        const unpinPath = `/coach/users/${user_id}/journalPins/${pinnedNote?.id}`;
        await api.delete(unpinPath);
      }
      updateNotes();
      displaySuccessToast({
        message: note.archived
          ? t("notes.moveBackSuccess")
          : t("notes.archiveSuccess")
      });
    } else {
      displayErrorToast({ message: t("errors.somethingWentWrong") });
    }
  };
  const updateNotes = () => {
    invalidateNotes();
    invalidatePinnedNotes();
  };

  useEffect(() => {
    if (!_.isEqual(sortedNotes, memoizedNotes)) {
      notesDispatch({
        type: NotesActions.Sort,
        payload: { notes: memoizedNotes }
      });
      previousNotesRef.current = memoizedNotes;
    }
  }, [notesDispatch, memoizedNotes, sortedNotes]);
  return {
    notes: sortedNotes,
    pinnedNotes,
    notesLoading,
    updateNotes,
    addNote,
    archiveNote,
    handleNotePinClick
  };
};
