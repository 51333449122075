import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getCareManagerTaskMetrics } from "./CareManagerTaskOverview.helpers";
import styles from "./CareManagerTaskOverview.module.scss";

import { ReactComponent as ClockRewindIcon } from "~/assets/clock-rewind.svg";
import Close from "~/assets/svgComponents/Close";
import FloatingElement from "~/components/floatingElement/FloatingElement";
import Heading from "~/components/heading/Heading";
import InfoTooltip from "~/components/infoTooltip/InfoTooltip";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import CustomCheckBoxSelect from "~/components/select/CustomCheckBoxSelect";
import SkeletonCareManagerTaskOverview from "~/components/skeletons/SkeletonCareManagerTaskOverview";
import PatientMetrics from "~/components/userMetric/PatientMetrics";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import round from "~/helpers/number/round";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import {
  useCarePrioritiesHistoricalData,
  useCarePrioritiesSummary
} from "~/hooks/graphql/useCareManagerOverview";
import { useNextRecommendedUser } from "~/hooks/useApi/useNextRecommandedUser";
import usePrograms from "~/hooks/useApi/usePrograms";
import { t } from "~/i18n";
import { CarePriorityType } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

type FilterProps = {
  label: string;
  onClear: () => void;
};

const Filter = ({ label, onClear }: FilterProps) => {
  if (label === DEFAULT_FILTER_VALUE) return null;

  return (
    <ButtonR
      className={styles.filter}
      type="secondary"
      size="sm"
      onClick={onClear}
      label={label}
      icon={<Close />}
      iconPosition="trailing"
    />
  );
};

const FiltersTooltip = ({ filters }: { filters: FilterProps[] }) => {
  return (
    <ul className={styles.filtersTooltip}>
      {filters.map((filter, index) => (
        <li key={`filter-tooltip-${index}`}>
          <p>{filter.label}</p>
          <ButtonR
            icon={<Close />}
            type="tertiary"
            size="sm"
            onClick={filter.onClear}
            className={styles.clear}
          />
        </li>
      ))}
    </ul>
  );
};

const Filters = ({
  filters,
  onClear
}: {
  filters: FilterProps[];
  onClear: () => void;
}) => {
  const firstFourFilters = filters.slice(0, 4);
  const restOfFilters = filters.slice(4);

  return (
    <div className={styles.filters}>
      {firstFourFilters.map((filter, index) => (
        <Filter key={`filter-${index}`} {...filter} />
      ))}
      {restOfFilters.length > 0 && (
        <FloatingElement
          triggerOnClick
          floatingContent={<FiltersTooltip filters={restOfFilters} />}
        >
          <div className={styles.moreFilters}>
            <span>+{restOfFilters.length}</span>
          </div>
        </FloatingElement>
      )}
      <ButtonR
        label={"Clear filters"}
        type="tertiary"
        size="sm"
        className={styles.clearFilters}
        onClick={onClear}
      />
    </div>
  );
};

const OverViewGridItem = ({
  title,
  filtered,
  action,
  information,
  numColumns = 1,
  approximate,
  total
}: {
  title: string;
  filtered?: number;
  action?: {
    label: string;
    onClick: () => void;
    loading?: boolean;
  };
  information?: string;
  numColumns?: number;
  approximate?: boolean;
  total: number;
}) => {
  return (
    <div
      className={styles.gridItem}
      style={{ gridColumn: `span ${numColumns}` }}
    >
      <div className={styles.information}>
        <p className={styles.title}>{title}</p>
        <p
          className={cx(styles.value, {
            [styles.approximate]: approximate
          })}
        >
          {filtered === undefined ? total : filtered}
          {filtered !== undefined && (
            <span className={styles.total}>{` / ${total}`}</span>
          )}
        </p>
      </div>
      {action && <ButtonR {...action} />}
      {information && (
        <span className={styles.tooltip}>
          <InfoTooltip content={information} />
        </span>
      )}
    </div>
  );
};

const CareManagerTaskOverview = () => {
  const navigate = useNavigate();

  const { programs, isLoading: programsLoading } = usePrograms();

  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);

  const {
    nextUserInfo,
    invalidate: recalculateNextUser,
    isLoading: nextUserLoading
  } = useNextRecommendedUser({
    carePriorities: selectedTasks,
    programCatalogItemIds: selectedPrograms
  });

  const {
    carePrioritiesSummary,
    invalidate: refetchSummary,
    isLoading: carePrioritiesLoading
  } = useCarePrioritiesSummary({
    carePriorities: selectedTasks,
    programCatalogItemIds: selectedPrograms
  });

  const { carePrioritiesHistoricalData } = useCarePrioritiesHistoricalData();

  const getNextStepUrl = () => {
    const programFilters = programs
      .filter((program) =>
        selectedPrograms.includes(program.programCatalogItemId)
      )
      .map((program) => ({
        programCatalogItemId: program.programCatalogItemId,
        programCatalogItemName: program.name
      }));

    const taskFilters = selectedTasks
      .map((task) => encodeURIComponent(task))
      .join(",");

    const queryParams = new URLSearchParams();

    if (programFilters.length > 0) {
      queryParams.append(
        "programFilters",
        encodeURIComponent(JSON.stringify(programFilters))
      );
    }

    if (taskFilters) {
      queryParams.append("taskFilters", taskFilters);
    }

    return `/tasks/${nextUserInfo?.userId}?${queryParams.toString()}`;
  };

  const formatFilterValues = (
    programFilters: string[],
    taskFilters
  ): FilterProps[] => {
    const formattedProgramFilters: FilterProps[] =
      programFilters.map((program) => ({
        onClear: () =>
          setSelectedPrograms((prev) => prev.filter((p) => p !== program)),
        label:
          programs.find((p) => p.programCatalogItemId === program)?.name ?? ""
      })) ?? [];

    const formattedTaskFilters: FilterProps[] =
      taskFilters.map((task) => ({
        onClear: () =>
          setSelectedTasks((prev) => prev.filter((t) => t !== task)),
        label: convertEnumToTitleCase(task)
      })) ?? [];

    return [...formattedProgramFilters, ...formattedTaskFilters];
  };

  useEffect(() => {
    refetchSummary();
    recalculateNextUser();
  }, [selectedPrograms, selectedTasks]);

  if (programsLoading || carePrioritiesLoading || nextUserLoading) {
    return <SkeletonCareManagerTaskOverview />;
  }

  const hasChosenFilters =
    Boolean(selectedPrograms.length) || Boolean(selectedTasks.length);

  return (
    <section className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <Heading level={"h2"}>Manage Users</Heading>
          <div className={styles.actions}>
            <Link to="/tasks/history">
              <ButtonR
                icon={<ClockRewindIcon />}
                iconPosition="leading"
                label="Activity history"
                className={styles.historyLink}
                type="tertiary"
                size="sm"
              />
            </Link>
            <CustomCheckBoxSelect
              onChange={setSelectedTasks}
              options={Object.values(CarePriorityType).map((task) => ({
                value: task,
                text: convertEnumToTitleCase(task)
              }))}
              selected={selectedTasks}
              noValuesSelectedPlaceholder="All tasks"
              includeApplyButton
            />
            <CustomCheckBoxSelect
              onChange={setSelectedPrograms}
              options={programs.map((program) => ({
                value: program.programCatalogItemId,
                text: program.name
              }))}
              selected={selectedPrograms}
              noValuesSelectedPlaceholder="All programs"
              includeApplyButton
            />
          </div>
        </div>
        {hasChosenFilters && (
          <Filters
            filters={formatFilterValues(selectedPrograms, selectedTasks)}
            onClear={() => {
              setSelectedPrograms([]);
              setSelectedTasks([]);
            }}
          />
        )}
      </div>

      <div className={styles.gridWrapper}>
        <div className={styles.grid}>
          <OverViewGridItem
            title={t("nextStep.overview.awaitingPriorities")}
            total={carePrioritiesSummary?.numAwaitingCarePriorities.total ?? 0}
            numColumns={2}
            action={{
              label: hasChosenFilters
                ? `${t("general.start")} (${
                    carePrioritiesSummary?.numAwaitingCarePriorities.filtered ??
                    0
                  })`
                : t("general.start"),
              onClick: () => navigate(getNextStepUrl()),
              loading: nextUserLoading || carePrioritiesLoading
            }}
            filtered={
              hasChosenFilters
                ? carePrioritiesSummary?.numAwaitingCarePriorities.filtered ?? 0
                : undefined
            }
          />
          <OverViewGridItem
            title={t("nextStep.overview.usersWithPriorities")}
            total={carePrioritiesSummary?.numAwaitingUsers.total ?? 0}
            filtered={
              hasChosenFilters
                ? carePrioritiesSummary?.numAwaitingUsers.filtered ?? 0
                : undefined
            }
            information="This metric shows how many users have care priorities that needs to be addressed. If you filter the view you will only see the care priorities for that cohort."
          />
          <OverViewGridItem
            title={t("nextStep.overview.prioritiesPerUser")}
            total={round(carePrioritiesSummary?.carePrioritiesPerUser) ?? 0}
            approximate
            information="This metric shows the average number of care priorities that each user has. If you filter the view you will see the average of that cohort."
          />
          <OverViewGridItem
            title={t("nextStep.overview.tasksCompleted")}
            total={carePrioritiesHistoricalData?.tasksCompletedThisWeek ?? 0}
            information="This metric tracks all of your finished care priorities that you have handled this week."
          />
          <OverViewGridItem
            title={t("nextStep.overview.overallUsersHelped")}
            total={carePrioritiesHistoricalData?.numUsersAssisted ?? 0}
            information="This metric shows all the users that you have helped via care priorities."
          />
        </div>
        <div className={styles.overview}>
          <Heading removeMargins level={"h3"}>
            {t("nextStep.carePriorities")}
          </Heading>
          <PatientMetrics
            metrics={getCareManagerTaskMetrics(carePrioritiesSummary)}
            renderValue={(value) => {
              if (hasChosenFilters) {
                return (
                  <p className={styles.metric}>
                    {value?.filtered} /{" "}
                    <span className={styles.total}>{value?.total}</span>
                  </p>
                );
              }
              return value?.total;
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default CareManagerTaskOverview;
