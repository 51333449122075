import classNames from "classnames";

import styles from "./PROResultRow.module.scss";

import { SurveyResultToDisplay } from "../../hooks/useSurveyResultsToDisplay";
import ResultBubble from "../resultBubble/ResultBubble";

import getRatingBasedOnResult from "~/helpers/getRatingBasedOnResult";
import { t } from "~/i18n";
import { Survey } from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

const PROResultRow = ({
  survey,
  hasRatings,
  surveyResultsToDisplay,
  highlightLatestSurveyResult,
  openColorOptions = false,
  handleCloseColorOptions,
  programId,
  locale
}: {
  surveyResultsToDisplay: SurveyResultToDisplay[];
  hasRatings: boolean;
  survey?: Survey;
  highlightLatestSurveyResult?: boolean;
  openColorOptions?: boolean;
  handleCloseColorOptions?: () => void;
  programId: string;
  locale: string;
}) => {
  const getCoachRating = (pro: SurveyResultToDisplay) => {
    return survey?.ratings?.find((rating) => {
      return rating.coachRatingValue === pro.coachRatingValue;
    });
  };

  return (
    <tr className={styles.resultRow}>
      <th className={styles.resultTitle}>{t("general.result")}</th>

      {surveyResultsToDisplay.map((pro, index) => {
        const rating = getRatingBasedOnResult(pro.result, survey?.ratings);
        const coachRating = getCoachRating(pro);

        const isHighlighted = pro.isLatestSurvey && highlightLatestSurveyResult;

        return (
          <td
            key={`result-bubble-pro-${pro.id}-${index}`}
            className={cx({
              [styles.highlight]: isHighlighted
            })}
          >
            <div className={styles.resultContainer} key={`result-${pro.id}`}>
              <ResultBubble
                rating={rating}
                coachRating={coachRating}
                pro={pro}
                survey={survey}
                hasRatings={hasRatings ?? false}
                isLastPro={pro.isLatestSurvey}
                openColorOptions={openColorOptions && isHighlighted}
                handleCloseColorOptions={
                  isHighlighted ? handleCloseColorOptions : undefined
                }
                programId={programId}
                locale={locale}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default PROResultRow;
