import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";

import useSessionHasExpired from "../useSessionHasExpired";

import { displayErrorToast } from "~/helpers/toast/displayToast";

export default function useGraphQLQuery<T>({
  queryKey,
  queryFn,
  staleTime,
  cacheTime,
  disabled,
  refetchInterval,
  modifyData,
  shouldRetry,
  refetchOnWindowFocus = true,
  errorToastMessage
}: {
  queryKey: QueryKey;
  queryFn: () => Promise<T>;
  staleTime?: number;
  cacheTime?: number;
  disabled?: boolean;
  refetchInterval?: number | (() => number | false);
  modifyData?: (data: T) => T;
  shouldRetry?: boolean | number;
  refetchOnWindowFocus?: boolean;
  errorToastMessage?: string;
}) {
  const queryClient = useQueryClient();

  const sessionExpired = useSessionHasExpired();

  const { data, isLoading, isError, status, isFetching, isSuccess } = useQuery({
    queryKey,
    queryFn,
    staleTime,
    enabled: !disabled && !sessionExpired,
    gcTime: cacheTime,
    refetchInterval,
    select: modifyData ? (data) => modifyData(data) : undefined,
    retry: shouldRetry,
    refetchOnWindowFocus
  });

  if (isError && errorToastMessage) {
    displayErrorToast({ message: errorToastMessage, id: queryKey.join("-") });
  }

  return {
    data,
    isError,
    isLoading,
    status,
    isFetching,
    isSuccess,
    setManuallyInCache: (value?: T) =>
      queryClient.setQueryData(queryKey, { ...data, data: value }),
    invalidate: () => queryClient.invalidateQueries({ queryKey })
  };
}
