import { useEffect, useState } from "react";

import styles from "./TaskHistory.module.scss";
import TaskHistoryItem from "./TaskHistoryItem";

import BackLink from "~/components/backLink/BackLink";
import Heading from "~/components/heading/Heading";
import Layout from "~/components/layout/Layout";
import PaginationWithSizeDropdown from "~/components/pagination/PaginationWithSizeDropdown";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { useCareManagerActionLogs } from "~/hooks/graphql/useCareManagerActionLogs";
import useUser from "~/hooks/useUser";

const TaskHistoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const { user } = useUser();
  const {
    careManagerActionLogs,
    isLoading: actionLogsLoading,
    invalidate: invalidateCareManagerActionLogs
  } = useCareManagerActionLogs({
    pageNumber: currentPage - 1,
    pageSize: pageSize,
    coachId: user?.id ?? ""
  });

  useEffect(() => {
    invalidateCareManagerActionLogs();
  }, [currentPage, pageSize]);

  if (actionLogsLoading) return <PulseLoader />;

  return (
    <Layout className={styles.layout}>
      <div className={styles.wrapper}>
        <div>
          <BackLink />
        </div>
        <div className={styles.header}>
          <Heading removeMargins level={"h2"}>
            Activity History
          </Heading>
        </div>
        {!careManagerActionLogs?.careManagerActionDtos.length ? (
          <div className={styles.noLogs}>
            Seems like you have no activity history. When you start interacting
            with your patients, you will see all the activity here.
          </div>
        ) : null}
        <div className={styles.actionLogs}>
          {careManagerActionLogs?.careManagerActionDtos.map((log, index) => (
            <TaskHistoryItem
              key={`action-item-${index}-${log?.userId}`}
              log={log}
            />
          ))}
        </div>
      </div>
      {careManagerActionLogs?.careManagerActionDtos.length ? (
        <PaginationWithSizeDropdown
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          totalRecords={careManagerActionLogs?.pageInfo.totalElements}
        />
      ) : null}
    </Layout>
  );
};

export default TaskHistoryPage;
