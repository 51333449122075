import { gql } from "graphql-request";
import { t } from "i18next";

import { gqlClient } from "../api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import {
  CareManagerActionLogsDto,
  CareManagerTasksDto,
  CarePriorities,
  GetNextUserFilterDtoInput,
  MessageSuggestionDto,
  MutationCalculateRankingScoreArgs,
  MutationMarkMessageSuggestionAsSentArgs,
  MutationReportMessageArgs,
  NextUserDto,
  QueryGetFallbackMessageArgs,
  QueryGetUserSymptomsLoggedMetadataArgs,
  UserContextInfo,
  UserSymptomLogMetadata
} from "~/typing/graphql/types";

export const getCareManagerTasks = async (
  coachId: number
): Promise<CareManagerTasksDto> => {
  const query = gql`
    query GetCareManagerTasks($coachId: Long!) {
      getCareManagerTasks(coachId: $coachId) {
        careManagerTaskDtos {
          estimatedUserCompletionTimeSeconds
          onHold
          onHoldUntil
          profilePictureUrl
          carePriorities
          timeOfRanking
          userFullName
          userId
          programCatalogItemName
        }
        estimatedChurnRiskCompletionTime
        estimatedFirstWeekMessageCompletionTime
        estimatedUnansweredMessageCompletionTime
        estimatedWeeklyFeedbackCompletionTime
      }
    }
  `;

  return await gqlClient
    .request(query, {
      coachId
    })
    .then((data: any) => data?.getCareManagerTasks || {});
};

export const getUserContextInfo = async (
  userId: string
): Promise<UserContextInfo> => {
  const query = gql`
    query GetUserContextInfo($userId: Long!) {
      getUserContextInfo(userId: $userId) {
        dayNumber
        locale
        programCatalogItemName
        userName
        weekNumber
        programCatalogItemId
        userActivityLastWeek {
          activeDates
          userId
        }
      }
    }
  `;

  return await gqlClient
    .request(query, {
      userId
    })
    .then((data: any) => data?.getUserContextInfo || {});
};

export const getCarePriorities = async (
  userId: string
): Promise<CarePriorities> => {
  const query = gql`
    query GetCarePriorities($userId: Long!) {
      getCarePriorities(userId: $userId) {
        priorities {
          metadata
          type
        }
        timeOfRanking
      }
    }
  `;

  return await gqlClient
    .request(query, {
      userId
    })
    .then((data: any) => data?.getCarePriorities || {})
    .catch(() => {
      displayErrorToast({
        message: t("nextStep.errors.fetchingCarePriorities")
      });
    });
};

export const calculateRankingScore = async (
  args: MutationCalculateRankingScoreArgs
): Promise<boolean> => {
  const mutation = gql`
    mutation CalculateRankingScore($userId: Long!) {
      calculateRankingScore(userId: $userId)
    }
  `;

  return await gqlClient
    .request(mutation, {
      userId: args.userId
    })
    .then((data: any) => data?.calculateRankingScore || {})
    .catch(() => {
      displayErrorToast({
        message: t("nextStep.errors.calculateRankingScore")
      });
    });
};

export const markMessageSuggestionAsSent = async (
  args: MutationMarkMessageSuggestionAsSentArgs
): Promise<number> => {
  const mutation = gql`
    mutation MarkMessageSuggestionAsSent(
      $messageSuggestionSentDto: MessageSuggestionSentDtoInput!
    ) {
      markMessageSuggestionAsSent(
        messageSuggestionSentDto: $messageSuggestionSentDto
      )
    }
  `;

  return await gqlClient
    .request(mutation, {
      messageSuggestionSentDto: args.messageSuggestionSentDto
    })
    .then((data: any) => data?.markMessageSuggestionAsSent || {})
    .catch(() => {
      displayErrorToast({
        message: t("nextStep.errors.markMessageSuggestionAsSent")
      });
    });
};

export const getUserSymptomsLoggedMetadata = async (
  args: QueryGetUserSymptomsLoggedMetadataArgs
): Promise<UserSymptomLogMetadata> => {
  const query = `
    query GetUserSymptomsLoggedMetadata($userId: Long!) {
      getUserSymptomsLoggedMetadata(userId: $userId) {
        mostRecentLogDate
        totalSymptomLogs
        messageSuggestion {
            message
            suggestionId
        }
        userSymptomHistories {
            severityScaleMax
            severityScaleMin
            symptomId
            symptomTitle
            logDetails {
                label
                loggedAt
                severity
            }
        }
        userSymptomStatuses {
            improving
            severe
            severityLabel
            symptomId
            symptomTitle
        }
    }
  }`;

  return await gqlClient
    .request(query, {
      userId: args.userId
    })
    .then((data: any) => data?.getUserSymptomsLoggedMetadata || {})
    .catch(() => {
      displayErrorToast({
        message: t("nextStep.errors.getUserSymptomsLoggedMetadata")
      });
    });
};

export const getFallbackMessage = async ({
  locale,
  userId
}: QueryGetFallbackMessageArgs): Promise<MessageSuggestionDto> => {
  const query = gql`
    query GetFallbackMessage($locale: String!, $userId: Long!) {
      getFallbackMessage(locale: $locale, userId: $userId) {
        message
        suggestionId
      }
    }
  `;

  return await gqlClient
    .request(query, {
      locale,
      userId
    })
    .then((data: any) => data?.getFallbackMessage || "")
    .catch(() => {
      console.error("Error fetching fallback message");
    });
};

export const reportMessage = async ({
  issues,
  message,
  note,
  suggestionId,
  userId
}: MutationReportMessageArgs): Promise<boolean> => {
  const mutation = gql`
    mutation ReportMessage(
      $issues: String!
      $message: String!
      $note: String
      $suggestionId: String!
      $userId: Long!
    ) {
      reportMessage(
        issues: $issues
        message: $message
        note: $note
        suggestionId: $suggestionId
        userId: $userId
      )
    }
  `;

  return await gqlClient
    .request(mutation, {
      issues,
      message,
      note,
      suggestionId,
      userId
    })
    .then((data: any) => data?.reportMessage || {})
    .catch(() => {
      displayErrorToast({
        message: t("nextStep.errors.reportMessage")
      });
    });
};

export const getNextUser = async (
  filters: GetNextUserFilterDtoInput
): Promise<NextUserDto> => {
  const query = `
    query GetNextUser($filters: GetNextUserFilterDtoInput!) {
      getNextUser(getNextUserFilterDto: $filters) {
          locale
          programCatalogId
          carePriorityTypes
          userId
          timeOfRanking 
      }
    }`;

  return await gqlClient
    .request(query, {
      filters
    })
    .then((data: any) => data?.getNextUser || {});
};

export const getCareManagerActionLogs = async (props: {
  coachId: number;
  pageNumber: number;
  pageSize: number;
}): Promise<CareManagerActionLogsDto> => {
  const query = `
    query GetCareManagerActionLogs($coachId: Long!, $pageNumber: Int!, $pageSize: Int!) {
      getCareManagerActionLogs(coachId: $coachId, pageNumber: $pageNumber, pageSize: $pageSize) {
        careManagerActionDtos {
          careManagerAction
          carePriorityType
          timeOfAction
          userId
        }
        pageInfo {
          totalElements
          totalPages
        }          
      }
    }`;

  return await gqlClient
    .request(query, {
      ...props
    })
    .then((data: any) => data.getCareManagerActionLogs || {});
};

export const getCareManagerOverview = async (
  props: GetNextUserFilterDtoInput
) => {
  const query = `
    query GetCareManagerActionLogs($props: GetNextUserFilterDtoInput!) {
      getCarePrioritiesSummary(getNextUserFilterDto: $props) {
        carePrioritiesBreakdown
        carePrioritiesPerUser
        numAwaitingCarePriorities {
          filtered
          total
        }
        numAwaitingUsers {
          filtered
          total
        }
      }
    }`;

  return await gqlClient
    .request(query, {
      props
    })
    .then((data: any) => data.getCarePrioritiesSummary || {});
};

export const getCarePrioritiesHistoricalData = async () => {
  const query = `
    query GetActionLogsSummary {
      getActionLogsSummary {
        numUsersAssisted
        tasksCompletedThisWeek
      }
    }`;

  return await gqlClient
    .request(query)
    .then((data: any) => data.getActionLogsSummary || {});
};
