import classNames from "classnames";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import styles from "./CareManagerTaskList.module.scss";
import { CareManagerTaskListItem } from "./CareManagerTaskListItem";

import { useCareManagerTaskListOptions } from "../useCareManagerTaskListOptions";

import AlertCircle from "~/assets/alert-circle.svg";
import CheckCircleBig from "~/assets/check-circle-big.svg";
import PaginationWithSizeDropdown from "~/components/pagination/PaginationWithSizeDropdown";
import SearchInput from "~/components/searchInput/SearchInput";
import CustomSelect from "~/components/select/CustomSelect";
import SkeletonCareManagerTasks from "~/components/skeletons/SkeletonCareManagerTasks";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import { useCareManagerTask } from "~/hooks/graphql/useCareManagerTasks";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { CareManagerTaskDto, CarePriorityType } from "~/typing/graphql/types";

const cx = classNames.bind(styles);

export const CareManagerTaskList = () => {
  const { careManagerTasks, isError, isLoading, status } = useCareManagerTask();

  const [careManagerTasksToDisplay, setCareManagerTasksToDisplay] = useState<
    CareManagerTaskDto[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [chosenProgramName, setChosenProgramName] = useState(
    DEFAULT_FILTER_VALUE
  );
  const [chosenCarePriority, setChosenCarePriority] = useState(
    DEFAULT_FILTER_VALUE
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(1);

  const [showOptions, setShowOptions] = useState(false);

  const {
    trackTaskPaginatorClicked,
    trackTaskRowsChanged
  } = useAmplitudeTracking();

  const { programOptions, taskOptions } = useCareManagerTaskListOptions({
    careManagerTasks: careManagerTasks?.careManagerTaskDtos ?? []
  });

  const filterTasks = useCallback(
    (careManagerTasks: CareManagerTaskDto[]) => {
      return careManagerTasks?.filter((task) => {
        const matchesSearch = searchValue
          ? task?.userFullName
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
          : true;

        if (!matchesSearch) return false;

        const matchesProgram =
          chosenProgramName && chosenProgramName !== DEFAULT_FILTER_VALUE
            ? task?.programCatalogItemName === chosenProgramName
            : true;

        if (!matchesProgram) return false;

        const matchesPriority =
          chosenCarePriority && chosenCarePriority !== DEFAULT_FILTER_VALUE
            ? task?.carePriorities.includes(
                chosenCarePriority as CarePriorityType
              )
            : true;

        if (!matchesPriority) return false;

        return true;
      });
    },
    [searchValue, chosenProgramName, chosenCarePriority]
  );

  useEffect(() => {
    if (careManagerTasks && status === "success") {
      if (
        careManagerTasks.careManagerTaskDtos &&
        careManagerTasks.careManagerTaskDtos.length > 0
      ) {
        const filteredCareManagerTasks = filterTasks(
          (careManagerTasks?.careManagerTaskDtos ?? []) as CareManagerTaskDto[]
        );

        const careManagerTasksToDisplay = filteredCareManagerTasks.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        ) as CareManagerTaskDto[];

        setCareManagerTasksToDisplay(careManagerTasksToDisplay);
        setTotalRecords(filteredCareManagerTasks?.length as number);
        setShowOptions(true);
      }
    }
  }, [
    careManagerTasks,
    currentPage,
    pageSize,
    searchValue,
    chosenProgramName,
    chosenCarePriority
  ]);

  const handleSetPageSize = (e: any) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    trackTaskRowsChanged();
  };

  const handlePageChange = (page: number) => {
    if (page < currentPage) {
      trackTaskPaginatorClicked({ direction: "previous" });
    } else {
      trackTaskPaginatorClicked({ direction: "next" });
    }
    setCurrentPage(page);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  const handleProgramChange = (e: any) => {
    setChosenProgramName(e.target.value ?? "N/A");
    setCurrentPage(1);
  };

  const handleCarePriorityChange = (e: any) => {
    setChosenCarePriority(e.target.value ?? "N/A");
    setCurrentPage(1);
  };

  return (
    <>
      <div className={styles.header}>
        <h2>{t("nextStep.careManagerTasks.title")} </h2>
        {!isLoading && (
          <div className={styles.filters}>
            <SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <CustomSelect
              options={taskOptions}
              renderOption={(option) => option.text}
              value={chosenCarePriority}
              onChange={handleCarePriorityChange}
            />
            <CustomSelect
              options={programOptions}
              renderOption={(option) => option.text}
              value={chosenProgramName}
              onChange={handleProgramChange}
            />
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {!isLoading &&
          careManagerTasksToDisplay?.map((task: CareManagerTaskDto, index) => (
            <CareManagerTaskListItem task={task} key={`key-cmtd-${index}`} />
          ))}
        {!isLoading && careManagerTasksToDisplay?.length === 0 && (
          <div className={styles.emptyContainer}>
            <div
              className={cx({
                [styles.empty]: !isError,
                [styles.error]: isError
              })}
            >
              <img src={isError ? AlertCircle : CheckCircleBig} alt="Check" />
              {!isError
                ? t("nextStep.careManagerTasks.noTasks")
                : t("nextStep.careManagerTasks.error")}
            </div>
          </div>
        )}
      </div>

      {showOptions && (
        <div className={styles.footer}>
          <PaginationWithSizeDropdown
            currentPage={currentPage}
            totalRecords={totalRecords}
            onPageSizeChange={handleSetPageSize}
            onPageChange={handlePageChange}
            pageSize={pageSize}
          />
        </div>
      )}
      {isLoading && <SkeletonCareManagerTasks />}
    </>
  );
};
