import classNames from "classnames";
import React, { SVGProps, ReactElement } from "react";

import styles from "./ButtonR.module.scss";

import PulseLoader from "~/components/pulseLoader/PulseLoader";

const cx = classNames.bind(styles);

type ButtonRPropsBase = {
  onClick?: () => void;
  disabled?: boolean;
  type?: "primary" | "secondary" | "tertiary" | "quaternary" | "warning";
  size?: "xs" | "sm" | "md" | "lg";
  iconPosition?: "leading" | "trailing";
  className?: string;
  loading?: boolean;
};

type ButtonRPropsWithLabel = ButtonRPropsBase & {
  label: string;
  icon?: ReactElement<SVGProps<SVGSVGElement>>;
};

type ButtonRPropsWithIcon = ButtonRPropsBase & {
  label?: string;
  icon: ReactElement<SVGProps<SVGSVGElement>>;
};

type ButtonRProps = ButtonRPropsWithLabel | ButtonRPropsWithIcon;

const ButtonR = ({
  label,
  onClick,
  disabled,
  type = "primary",
  size = "md",
  icon,
  iconPosition = "leading",
  className,
  loading
}: ButtonRProps) => {
  const buttonClasses = cx(styles.button, styles[type], styles[size], {
    [styles.reverse]: iconPosition === "trailing",
    [styles.square]: !label,
    [styles.leading]: icon && iconPosition === "leading",
    [styles.trailing]: icon && iconPosition === "trailing",
    [styles.loading]: loading,
    [className || ""]: className
  });

  const hasInvertedLoader = type === "quaternary" || type === "tertiary";

  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled || loading}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick?.();
        }
      }}
      tabIndex={0}
    >
      {loading ? (
        <PulseLoader inverted={hasInvertedLoader} className={styles.loader} />
      ) : (
        <>
          {icon && <span className={styles.iconContainer}>{icon}</span>}
          {label}
        </>
      )}
    </button>
  );
};

export default ButtonR;
