import { QueryKeyFactory } from "./queryKeysFactory";

import useGraphQLQuery from "../graphql/useGraphQLQuery";

import { getNextUser } from "~/api/graphql/nextStep";
import { GetNextUserFilterDtoInput } from "~/typing/graphql/types";

export const useNextRecommendedUser = (filters: GetNextUserFilterDtoInput) => {
  const { data, ...rest } = useGraphQLQuery({
    queryKey: QueryKeyFactory.nextRecommendedUser,
    queryFn: () => getNextUser(filters),
    shouldRetry: 1
  });

  return {
    nextUserInfo: data,
    ...rest
  };
};
