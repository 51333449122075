import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";

import styles from "./BackLink.module.scss";

import Left from "~/assets/left-next-step.svg";

const BackLink = ({ url, text }: { text?: string; url?: string }) => {
  const navigate = useNavigate();

  return (
    <Link tabIndex={-1} to={url ?? ".."}>
      <button
        className={styles.backLink}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate(url ?? "..");
          }
        }}
      >
        <img src={Left} alt="Back" /> {text ?? t("general.back")}
      </button>
    </Link>
  );
};

export default BackLink;
