import React from "react";

import styles from "./InfoTooltip.module.scss";

import FloatingElement from "../floatingElement/FloatingElement";

import QuestionMark from "~/assets/info-circle.svg";

type InfoToolipProps = {
  content: React.ReactNode;
};

const InfoTooltip = ({ content }: InfoToolipProps) => {
  return (
    <FloatingElement floatingContent={content}>
      <div className={styles.wrapper}>
        <img src={QuestionMark} />
      </div>
    </FloatingElement>
  );
};

export default InfoTooltip;
