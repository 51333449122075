import styles from "./PaginationWithSizeDropdown.module.scss";

import CustomSelect from "../select/CustomSelect";

import ChevronLeft from "~/assets/chevron-left-next-step.svg";
import ChevronRight from "~/assets/chevron-right-next-step.svg";
import { t } from "~/i18n";

const NUMBER_OF_ROWS_OPTIONS = [
  { value: 5, text: 5 },
  { value: 10, text: 10 }
];

const PaginationWithSizeDropdown = ({
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalRecords
}: {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (page: number) => void;
}) => {
  const totalPages = pageSize ? Math.ceil(totalRecords / pageSize) : 0;

  return (
    <div className={styles.pagination}>
      {t("nextStep.careManagerTasks.rowsPerPage")}
      <CustomSelect
        options={NUMBER_OF_ROWS_OPTIONS}
        renderOption={(option) => option.text}
        value={pageSize}
        onChange={(e) => onPageSizeChange(e.target.value)}
        className={styles.select}
      />
      <div className={styles.pages}>
        {pageSize * (currentPage - 1) + 1} -{" "}
        {Math.min(pageSize * currentPage, totalRecords)}{" "}
        {t("nextStep.careManagerTasks.of")} {totalRecords}
      </div>
      <div className={styles.prevNext}>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src={ChevronLeft} />
        </button>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img src={ChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default PaginationWithSizeDropdown;
