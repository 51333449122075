import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SurveyResult.module.scss";
import SurveyResultRow from "./SurveyResultRow";

import Heading from "~/components/heading/Heading";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import Paragraph from "~/components/paragraph/Paragraph";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import { useApproveAllSurveyResults } from "~/hooks/graphql/useApproveAllSurveyResults";
import { useGetCarePriorities } from "~/hooks/graphql/useCareManagerTasks";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useSurvey from "~/hooks/useApi/useSurvey";
import PROTemplate from "~/pages/user/pro/components/proTemplate/PROTemplate";
import { SurveyResultMetadataList } from "~/typing/graphql/types";

type SurveyResultProps = {
  userId: string;
  userName: string;
  metadata: SurveyResultMetadataList;
  programId: string;
  locale: string;
};

const SurveyResult = ({
  userId,
  userName,
  metadata,
  programId,
  locale
}: SurveyResultProps) => {
  const { t } = useTranslation();

  // Local state
  const [openSurvey, setOpenSurvey] = useState(false);
  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);
  const [openColorOptions, setOpenColorOptions] = useState(false);
  const { invalidate: invalidateCarePriorities } = useGetCarePriorities(userId);

  const currentSurveyMetadata = useMemo(
    () => metadata?.surveyResultMetadataList[currentSurveyIndex],
    [metadata, currentSurveyIndex]
  );

  const { survey, isError, isLoading, invalidate } = useSurvey({
    programCatalogItemId: programId,
    locale: locale,
    surveyId: currentSurveyMetadata?.surveyId,
    disabled: !openSurvey || !currentSurveyMetadata
  });

  const {
    approveAllSurveyResultsAsync,
    isPending: approvalIsLoading
  } = useApproveAllSurveyResults();

  const handleGoToNextColumn = async (dir: "left" | "right") => {
    const index =
      dir === "left" ? currentSurveyIndex - 1 : currentSurveyIndex + 1;

    if (metadata?.surveyResultMetadataList[index]) {
      setCurrentSurveyIndex(index);
    }
  };
  const queryClient = useQueryClient();

  const invalidateRelevantData = async () => {
    //Invalidate the care priorities to refetch the data and show that the survey has been approved
    await invalidateCarePriorities();
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.users.surveyResults(
        userId,
        currentSurveyMetadata?.surveyId
      )
    });
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.users.detail(programId, locale, userId)
    });
  };

  const handleApproveAll = async () => {
    await approveAllSurveyResultsAsync({
      userId,
      surveyId: currentSurveyMetadata?.surveyId,
      coachRatingValue: currentSurveyMetadata?.score ?? 0
    });

    invalidateRelevantData();
  };

  const handleOpenColorOptions = () => {
    //Open the color options for the current survey
    //We can't open if the survey has less than 2 ratings
    if (!survey?.ratings) return;
    if (survey?.ratings.length < 2) return;
    setOpenColorOptions(true);
  };

  const handleCloseColorOptions = () => {
    setOpenColorOptions(false);
  };

  useEffect(() => {
    invalidate();
  }, [currentSurveyMetadata]);

  return (
    <div>
      <Heading level="h4">{t("nextStep.diga.trigger")}</Heading>

      <Paragraph size="sm" text={t("nextStep.survey.trigger", { userName })} />

      <Heading level="h4">{t("nextStep.survey.PROs")}</Heading>

      {metadata?.surveyResultMetadataList.length === 0 && (
        <Paragraph text={t("nextStep.survey.errors.noSurveys")} size="sm" />
      )}

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.headrow}>
              <th>{t("nextStep.survey.completed")}</th>
              <th>{t("nextStep.survey.score")} </th>
              <th>{t("nextStep.survey.name")}</th>
              <th className={styles.actions}></th>
            </tr>
          </thead>
          <tbody>
            {metadata &&
              metadata?.surveyResultMetadataList.map((survey, index) => {
                if (!survey) return null;
                return (
                  <SurveyResultRow
                    key={survey?.surveyResultId}
                    userId={userId}
                    metadata={survey}
                    handleOpenSurvey={() => {
                      setCurrentSurveyIndex(index);
                      setOpenSurvey(true);
                    }}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      {openSurvey && (
        <Modal
          title={currentSurveyMetadata?.surveyName || ""}
          onClose={() => setOpenSurvey(false)}
          className={styles.modal}
        >
          {isError && (
            <Paragraph
              text={t("nextStep.survey.errors.surveyNotFound")}
              size="sm"
            />
          )}
          {isLoading && <LoadingSpinner />}
          {!isError && !isLoading && survey && (
            <PROTemplate
              survey={survey}
              user_id={userId}
              program_id={programId}
              survey_id={currentSurveyMetadata?.surveyId}
              locale={locale}
              highlightLatestSurveyResult
              openColorOptions={openColorOptions}
              handleCloseColorOptions={handleCloseColorOptions}
              onSurveyResultChange={invalidateRelevantData}
            />
          )}
          <div className={styles.footer}>
            <div className={styles.traversal}>
              <ButtonR
                label={t("general.prev")}
                onClick={() => handleGoToNextColumn("left")}
                disabled={currentSurveyIndex === 0}
                type="tertiary"
              />
              <ButtonR
                label={t("general.next")}
                onClick={() => handleGoToNextColumn("right")}
                disabled={
                  currentSurveyIndex >=
                  metadata.surveyResultMetadataList.length - 1
                }
                type="tertiary"
              />
            </div>
            <div className={styles.actions}>
              <ButtonR
                label={t("nextStep.survey.changeResult")}
                onClick={handleOpenColorOptions}
                type="secondary"
                disabled={!survey?.ratings || survey?.ratings.length < 2}
              />
              <ButtonR
                label={t("general.approveAll")}
                onClick={handleApproveAll}
                disabled={currentSurveyMetadata?.approved}
                loading={approvalIsLoading}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SurveyResult;
