import {
  readOtherCoachMessage,
  readAllMessages
} from "~/api/requests/messageRequests";
import { UnreadMessage } from "~/pages/user/userPage/unreadMessagesReducer";
import { UserContextInfo } from "~/typing/graphql/types";

export const readUnreadMessages = ({
  urneadMessages,
  userContext,
  userId
}: {
  urneadMessages: UnreadMessage[];
  userId: string;
  userContext?: UserContextInfo;
}) => {
  if (!userContext) return;

  urneadMessages.forEach((message) => {
    if (!message.toAuthUser) {
      readOtherCoachMessage(
        userContext.programCatalogItemId,
        userContext.locale,
        userId,
        message.id
      );
    }
  });

  readAllMessages({
    programId: userContext.programCatalogItemId,
    locale: userContext.locale,
    userId: userId
  });
};
