import * as Sentry from "@sentry/react";

import { displayErrorToast } from "~/helpers/toast/displayToast";

/**
 * The constructor for the Exception class
 * @interface ExceptionConstructor
 * @public
 * @property {string} message - The message of the exception (required)
 * @property {string} title - The title of the exception (optional)
 * @property {Record<string, any>} context - The context of the exception (optional)
 * @property {number} statusCode - The status code of the exception (optional)
 * @property {string} path - The path of the file and function where the exception was thrown, used to identify the known error (optional)
 */
type ExceptionConstructor = {
  message: string;
  title?: string;
  context?: Record<string, any>;
  statusCode?: number;
  path?: string;
};

export enum ExceptionType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DEBUG = "debug",
  FATAL = "fatal"
}

/**
 * Exception class with status code, title, and description properties
 * @class Exception
 * @extends Error
 * @public
 */
export class Exception extends Error {
  public context: any; // literally could be anything
  public statusCode: number;
  public title: string;
  public message: string;
  public path: string;

  constructor({
    message,
    title,
    context,

    statusCode = 400,
    path
  }: ExceptionConstructor) {
    super(message);
    this.name = message;
    this.title = title || "Bad Request";
    this.message = message;
    this.context = context || null;
    this.statusCode = statusCode;
    this.path = path || "";
  }

  /**************************************************************************
   * Getters
   **************************************************************************/

  /**
   * Get the status code of the exception
   * @returns {number} - the status code
   */
  public getStatusCode(): number {
    return this.statusCode;
  }

  /**
   * Get the message of the exception
   * @returns {string} - the message
   */
  public getMessage(): string {
    return this.message;
  }

  /**
   * Get the title of the exception
   * @returns {string} - the title
   */
  public getTitle(): string {
    return this.title;
  }

  /**
   * Get the name of the exception
   * @returns {string} - the name
   */
  public getName(): string {
    return this.name;
  }

  /**************************************************************************
   * Setters
   **************************************************************************/

  /**
   * Set the message of the exception
   * @param {string} message - the title
   */
  public setMessage(message: string) {
    this.message = message;
    return this;
  }

  /**
   * Set the status code of the exception
   * @param {number} statusCode - the status code
   */
  public setStatusCode(statusCode: number) {
    this.statusCode = statusCode;
    return this;
  }

  /**
   * Set the title of the exception
   * @param {string} title - the title
   */
  public setTitle(title: string) {
    this.title = title;
    return this;
  }

  /**
   * Set the name of the exception
   * @param {string} name - the name
   */
  public setName(name: string) {
    this.name = name;
    return this;
  }

  /**************************************************************************
   * Static Methods
   **************************************************************************/

  /**
   * Log the exception to the console and Sentry and display it as a toast message in the UI
   */
  public log(type: ExceptionType = ExceptionType.ERROR) {
    if (this.isNotAuthenticated()) {
      return this;
    }

    const error = new Error(this.getMessage());
    error.name = this.getName() || "UnknownError";

    const sentryExtraData: { [key: string]: any } = {};

    if (this.context.error.isAxiosError) {
      const { url, method, data, headers } = this.context.error.config || {};
      const { status, statusText } = this.context.error.response || {};

      sentryExtraData.url = url;
      sentryExtraData.method = method;
      sentryExtraData.data = data;
      sentryExtraData.headers = headers;
      sentryExtraData.status = status;
      sentryExtraData.statusText = statusText;
      sentryExtraData.message = this.context.error.message;
      sentryExtraData.code = this.context.error.code;
    }

    Sentry.setExtras({
      title: this.getTitle(),
      statusCode: this.getStatusCode(),
      ...sentryExtraData
    });
    if (type === ExceptionType.ERROR) {
      Sentry.captureException(error);
      console.error(this);
    } else {
      Sentry.captureMessage(JSON.stringify(this), type);
      console.warn(this);
    }
    return this;
  }

  /**
   * Display the exception as a toast message in the UI
   */
  public display(display: boolean = true) {
    if (this.isNotAuthenticated()) {
      window.dispatchEvent(new Event("session-expired"));
      return this;
    }
    if (!display) {
      return this;
    }
    displayErrorToast({ message: `${this.title}_:_${this.message}` });
    return this;
  }

  public isNotAuthenticated() {
    const response = this?.context?.response || "";
    const message = this?.message || "";
    const status = this?.statusCode || 0;
    return (
      response.includes("401") || message.includes("401") || status === 401
    );
  }
}
