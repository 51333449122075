import { useEffect, useState } from "react";

import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import { Maybe } from "~/gql/graphql";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { SelectOption } from "~/typing/carePortalTypes";
import { CareManagerTaskDto } from "~/typing/graphql/types";

export const useCareManagerTaskListOptions = ({
  careManagerTasks
}: {
  careManagerTasks?: Maybe<CareManagerTaskDto>[];
}) => {
  const [programOptions, setProgramOptions] = useState<SelectOption[]>([]);
  const [taskOptions, setTaskOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (
      !careManagerTasks?.length ||
      programOptions.length ||
      taskOptions.length
    )
      return;

    const tempProgramOptions: Map<string, string> = new Map();
    const tempTaskOptions: Map<string, string> = new Map();

    careManagerTasks?.forEach((task) => {
      if (task) {
        tempProgramOptions.set(
          task.programCatalogItemId,
          task.programCatalogItemName
        );
        task?.carePriorities.forEach((carePriority) => {
          tempTaskOptions.set(
            carePriority ?? "",
            convertEnumToTitleCase(carePriority)
          );
        });
      }
    });

    // convert map to array
    const programTempOptions = Array.from(tempProgramOptions).map(
      ([value, text]) => ({
        value,
        text
      })
    );

    const taskTempOptions = Array.from(tempTaskOptions).map(
      ([value, text]) => ({
        value,
        text
      })
    );

    setProgramOptions(
      [{ text: "All programs", value: DEFAULT_FILTER_VALUE }].concat(
        programTempOptions
      )
    );

    setTaskOptions(
      [{ text: "All tasks", value: DEFAULT_FILTER_VALUE }].concat(
        taskTempOptions
      )
    );
  }, [careManagerTasks]);

  return { programOptions, taskOptions };
};
