import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetCarePriorities } from "~/hooks/graphql/useCareManagerTasks";
import { NextStepURLParams } from "~/typing/carePortalTypes";
import { CarePriorities } from "~/typing/graphql/types";

export const useCarePrioritiesToDisplay = () => {
  const [carePrioritiesToDisplay, setCarePrioritiesToDisplay] = useState<
    CarePriorities | undefined
  >();
  const [currentUserId, setCurrentUserId] = useState<string | undefined>();

  const { user_id = "" } = useParams<NextStepURLParams>();

  const {
    carePriorities,
    status: carePrioritiesStatus,
    isLoading: carePrioritiesLoading,
    invalidate: invalidateCarePriorities
  } = useGetCarePriorities(user_id);

  useEffect(() => {
    if (carePrioritiesStatus === "success" && !carePrioritiesLoading) {
      initializeCarePrioritiesToDisplay();
    }
  }, [carePriorities, carePrioritiesStatus, user_id, carePrioritiesLoading]);

  //Set the initial care priorities so that we can check them off as we go.
  //We only want to set the tabs once at the first successful fetch, so we don't remove the tabs when the care priorities are refetched.
  //If the user refreshes the page, the tabs will be set again.
  const initializeCarePrioritiesToDisplay = () => {
    if (!carePrioritiesToDisplay) {
      setCarePrioritiesToDisplay(carePriorities);
      return;
    }

    if (user_id !== currentUserId) {
      setCurrentUserId(user_id);
      setCarePrioritiesToDisplay(carePriorities);
    } else {
      setCarePrioritiesToDisplay({
        priorities:
          carePrioritiesToDisplay?.priorities.map((priority) => {
            const unCompleted = carePriorities?.priorities.find(
              (completedPriority) => completedPriority?.type === priority?.type
            );

            return unCompleted ?? priority;
          }) ?? [],
        timeOfRanking: carePriorities?.timeOfRanking
      });
    }
  };

  return {
    carePriorities: carePrioritiesToDisplay,
    carePrioritiesLoading,
    invalidateCarePriorities
  };
};
