import {
  ChevronDown,
  ChevronUp,
  LinkExternal01
} from "@sidekickhealth/sidekick-icons";
import classNames from "classnames";
import { t } from "i18next";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./NextStepUserHeader.module.scss";

import Avatar from "~/components/avatar/Avatar";
import PinnedNotes from "~/components/notes/PinnedNotes";
import UserNotes from "~/components/notes/UserNotesModal";
import Pill from "~/components/pill/Pill";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import { useUserContextInfo } from "~/hooks/graphql/useCareManagerTasks";
import useProgram from "~/hooks/useApi/useProgram";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import { useChildrenTabIndexToggle } from "~/hooks/useChildrenTabIndexToggle";
import colors from "~/styles/colors";
import { NextStepURLParams } from "~/typing/carePortalTypes";

const NextStepUserHeader = () => {
  const [expandedNotes, setNotesExpanding] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const { parentRef: notesRef } = useChildrenTabIndexToggle({
    childrenHidden: !expandedNotes
  });

  const { user_id = "" } = useParams<NextStepURLParams>();

  const { userContextInfo } = useUserContextInfo({ userId: user_id });

  const { userDetail } = useUserDetail({
    userId: user_id,
    programCatalogItemId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? ""
  });

  const { program } = useProgram({
    programCatalogItemId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? ""
  });

  const userUrl = `/program/${userContextInfo?.programCatalogItemId}/locale/${userContextInfo?.locale}/users/${user_id}`;

  return (
    <>
      <div className={styles.userHeader}>
        <div className={styles.user}>
          <Avatar user={userDetail?.user} size="lg" />
          <div className={styles.userInfo}>
            <div className={styles.userHeading}>
              <Pill
                title={`T${program?.tier}`}
                backgroundColor={colors.darkBlue40}
                className={styles.tier}
              />
              <p title={userContextInfo?.userName} className={styles.userName}>
                {userContextInfo?.userName}{" "}
              </p>
              <Link
                to={userUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.viewUser}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    window.open(userUrl, "_blank");
                  }
                }}
              >
                <ButtonR
                  type="tertiary"
                  size="xs"
                  icon={<LinkExternal01 />}
                  iconPosition="trailing"
                  label={t("general.viewUser")}
                  className={styles.viewUserButton}
                />
              </Link>
            </div>

            <div className={styles.program}>
              <div className={styles.programInfo}>
                <p>{program?.name}</p>
                <p>
                  {`Week ${userContextInfo?.weekNumber}`}
                  {`, Day ${userContextInfo?.dayNumber}`}
                </p>
              </div>
              <ButtonR
                size="xs"
                type="secondary"
                onClick={() => setNotesExpanding((prev) => !prev)}
                label={t(
                  expandedNotes ? "general.showLess" : "general.showMore"
                )}
                icon={expandedNotes ? <ChevronDown /> : <ChevronUp />}
                iconPosition="trailing"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.notes, {
          [styles.visible]: expandedNotes
        })}
        ref={notesRef}
      >
        <div className={styles.notesWrapper}>
          <PinnedNotes showAllNotes={() => setShowNotesModal(true)} />
        </div>
      </div>

      {showNotesModal && <UserNotes onClose={() => setShowNotesModal(false)} />}
    </>
  );
};

export default NextStepUserHeader;
