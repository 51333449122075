import classNames from "classnames/bind";
import { ReactNode } from "react";

import styles from "./PatientMetrics.module.scss";

import ArrowIcon from "~/assets/arrow-right-thick.svg";
import InfoTooltip from "~/components/infoTooltip/InfoTooltip";
import config from "~/config";
import round from "~/helpers/number/round";
import { UserMetricData } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);

const Metric = <T,>({
  metric: { title, metric, value, change, info, icon },
  renderValue
}: {
  metric: UserMetricData<T>;
  renderValue?: (value: T | undefined) => ReactNode;
}) => {
  const getValue = ({
    value,
    metric
  }: {
    value: T | undefined;
    metric?: string;
  }) => {
    if (value === undefined) {
      return "-";
    }

    const finalValue =
      typeof value === "number"
        ? round(value, 2)?.toLocaleString(config.isAnthem ? "en-US" : "en-GB")
        : value;

    return `${finalValue} ${metric ?? ""}`;
  };

  const ChangeIndicator = () => {
    if (!change) return null;

    const classes = cx({
      change: true,
      positive: !change.negative && change.value !== 0,
      negative: change.negative !== undefined && change.negative,
      up: change.value > 0,
      down: change.value < 0,
      zero: change.value === 0
    });

    return (
      <div className={classes}>
        <img className={cx({ down: change.value < 0 })} src={ArrowIcon} />
        {`${round(Math.abs(change.value), 0)}%`}
      </div>
    );
  };

  const finalValue = renderValue
    ? renderValue(value)
    : getValue({ value, metric });

  return (
    <div className={cx({ metric: true })}>
      <div className={styles.metricInfo}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <p>{title}</p> {info && <InfoTooltip content={info} />}
          </div>
          <p className={styles.value}>{finalValue}</p>
        </div>
      </div>
      <ChangeIndicator />
    </div>
  );
};

type PatientMetricsProps<T> = {
  metrics: UserMetricData<T>[];
  renderValue?: (value: T | undefined) => ReactNode;
};

const PatientMetrics = <T,>({
  metrics,
  renderValue
}: PatientMetricsProps<T>) => {
  return (
    <section className={styles.metricsWrapper}>
      <div className={styles.grid}>
        {metrics.map((metric, index) => (
          <Metric
            key={`metric-${metric.title}-${index}`}
            metric={metric}
            renderValue={renderValue}
          />
        ))}
      </div>
    </section>
  );
};

export default PatientMetrics;
