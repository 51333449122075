import useGraphQLQuery from "./useGraphQLQuery";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";

import {
  getCareManagerOverview,
  getCarePrioritiesHistoricalData
} from "~/api/graphql/nextStep";
import { Maybe } from "~/gql/graphql";
import {
  ActionLogsSummaryDto,
  CarePrioritiesSummaryDto,
  GetNextUserFilterDtoInput
} from "~/typing/graphql/types";

export const useCarePrioritiesSummary = (
  filters: GetNextUserFilterDtoInput
) => {
  const {
    data,
    isLoading,
    isError,
    status,
    isFetching,
    invalidate
  } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.carePrioritiesSummary,
    queryFn: () => getCareManagerOverview(filters)
  });

  return {
    carePrioritiesSummary: data as Maybe<CarePrioritiesSummaryDto>,
    isLoading,
    isError,
    invalidate,
    status,
    isFetching
  };
};

export const useCarePrioritiesHistoricalData = () => {
  const {
    data,
    isLoading,
    isError,
    status,
    isFetching,
    invalidate
  } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.carePrioritiesHistoricalData,
    queryFn: () => getCarePrioritiesHistoricalData()
  });

  return {
    carePrioritiesHistoricalData: data as Maybe<ActionLogsSummaryDto>,
    isLoading,
    isError,
    invalidate,
    status,
    isFetching
  };
};
