import styles from "./SkeletonCareManagerTaskOverview.module.scss";
import SkeletonShimmer from "./SkeletonShimmer";

import Heading from "../heading/Heading";

const SkeletonCareManagerTaskOverview = () => {
  return (
    <>
      <Heading level={"h2"}>Manage Users</Heading>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div
            className={`skeleton ${styles.gridItem}`}
            style={{ gridColumn: "span 2" }}
          />
          <div className={`skeleton ${styles.gridItem}`} />
          <div className={`skeleton ${styles.gridItem}`} />
          <div className={`skeleton ${styles.gridItem}`} />
          <div className={`skeleton ${styles.gridItem}`} />
        </div>
        <div className={`skeleton ${styles.overview}`} />
        <SkeletonShimmer />
      </div>
    </>
  );
};

export default SkeletonCareManagerTaskOverview;
