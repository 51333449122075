import {
  AlertTriangle,
  MessageNotificationSquare,
  AnnotationPlus,
  ClipboardCheck,
  MessagePlusSquare,
  BarChart12,
  FileHeart02,
  FileQuestion02
} from "@sidekickhealth/sidekick-icons";

import { Maybe } from "~/gql/graphql";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { UserMetricData } from "~/typing/carePortalTypes";
import {
  CarePrioritiesSummaryDto,
  CarePriorityType,
  TotalAndFilteredDto
} from "~/typing/graphql/types";
export const getCareManagerTaskMetrics = (
  careManagerTasks: Maybe<CarePrioritiesSummaryDto>
): UserMetricData<{ total: number; filtered?: number }>[] => {
  // get total care priorities of each type of CarePriority in careManagerTasks.carePriorities
  const breakdown: { [key: string]: TotalAndFilteredDto } =
    careManagerTasks?.carePrioritiesBreakdown;

  const getIcon = (title: string) => {
    switch (title) {
      case CarePriorityType.HighChurnRisk:
        return <AlertTriangle />;
      case CarePriorityType.NewMessage:
        return <MessageNotificationSquare />;
      case CarePriorityType.WeeklyFeedback:
        return <AnnotationPlus />;
      case CarePriorityType.SurveyResultCreated:
        return <ClipboardCheck />;
      case CarePriorityType.FirstWeek:
        return <MessagePlusSquare />;
      case CarePriorityType.ProgramProgression:
        return <BarChart12 />;
      case CarePriorityType.BehaviouralSurveyFeedback:
        return <FileHeart02 />;
      case CarePriorityType.SymptomLogFeedback:
        return <FileQuestion02 />;
      default:
        return null;
    }
  };

  if (!breakdown) return [];

  const carePriorityMetrics: UserMetricData<{
    total: number;
    filtered?: number;
  }>[] = Object.keys(breakdown).map((key) => ({
    title: convertEnumToTitleCase(key),
    value: {
      total: breakdown[key].total,
      filtered: breakdown[key].filtered
    },
    icon: getIcon(key)
  }));

  return carePriorityMetrics;
};
